@font-face {
  font-family: FoundersGrotesk;
  src: url(../fonts/FoundersGroteskWeb-Regular.2c41d29b.eot) format("eot"),
  url(../fonts/FoundersGroteskWeb-Regular.9e11debf.woff) format("woff"),
  url(../fonts/FoundersGroteskWeb-Regular.84ea80e7.woff2) format("woff2");
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 300;
  src: url(../fonts/FoundersGroteskWeb-Light.d84b2fa8.eot) format("eot"),
  url(../fonts/FoundersGroteskWeb-Light.9419e23f.woff) format("woff"),
  url(../fonts/FoundersGroteskWeb-Light.8f321396.woff2) format("woff2");
}
@font-face {
  font-family: FoundersGrotesk;
  font-weight: 500;
  src: url(../fonts/FoundersGroteskWeb-Medium.62da9f3f.eot) format("eot"),
  url(../fonts/FoundersGroteskWeb-Medium.93ac32e3.woff) format("woff"),
  url(../fonts/FoundersGroteskWeb-Medium.1ed321a7.woff2) format("woff2");
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 600;
  src: url(../fonts/FoundersGroteskWeb-Semibold.34d2d2f6.eot) format("eot"),
  url(../fonts/FoundersGroteskWeb-Semibold.297e439e.woff) format("woff"),
  url(../fonts/FoundersGroteskWeb-Semibold.4acdaa88.woff2) format("woff2");
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 700;
  src: url(../fonts/FoundersGroteskWeb-Bold.2a0607d0.eot) format("eot"),
  url(../fonts/FoundersGroteskWeb-Bold.8eefac64.woff) format("woff"),
  url(../fonts/FoundersGroteskWeb-Bold.66ac3a14.woff2) format("woff2");
}
